.page5Container {
    display: flex;
    align-items: center;
    

    .page5Container div {
        flex: 1;
    }
    
    .text-container h3, .text-container, .title {
        color: #FE3F00;
        font-weight: bold;
        font-size: 45px;
        margin-left: auto;
    }
    
    .text-container h3 {
        margin-bottom: 24px;
    }
    
   
    .text-container a {

        height: 36px;
        text-align: left;
        font-family: "Apple SD Gothic Neo", Regular;
        letter-spacing: 0.24px;
        color: #827C85;
        opacity: 1;
        font-size: 22px;
        font-weight: normal;
        transition: 0.2s;
        text-decoration: none;
        position: absolute;
        top: 20px;
    }

    .text-container .download {
        text-decoration: underline;
        border-bottom: 1px solid #000;
        height: 45px;
        width: 120px;
        position: relative;
        &:hover {
            border-bottom-color: #FE3F00;
        }
    }

    .text-container a:hover {
        color: #FE3F00;
        top: 15px;
    }
    
    .text-container h3 span {
        color: #3B3655;
    }
    
    .intro {
        text-align: left;
        font-family: "Apple SD Gothic Neo", Regular;
        font-weight: 500;
        letter-spacing: 0.24px;
        line-height: 30px;
        color: #3B3655;
        opacity: 1;
        font-size: 22px;
        margin: auto;
        display: block;
    }
    
    .img-container {
        text-align: center;
        margin-left: -50px;
    }
    
    
    .img-container img {
        height: 600px;
    }

   
}

.page5 {
    margin-top: 500px;
}

.page5title {
    color: #3B3655;
    font-weight: bold;
    font-size: 45px;
    display: block;
    margin: auto;
}

.sub-head {
    text-align: left;
    font-family: "Apple SD Gothic Neo", Regular;
    font-size: 18px;
    letter-spacing: 0.19px;
    color: #827C85;
    opacity: 1;
    padding-bottom: 8px;
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {

    .page5Container {
        display: flex;
        align-items: center;
        
    
        .page5Container div {
            flex: 1;
        }
        
        .text-container h3, .text-container, .title {
            color: #FE3F00;
            font-weight: bold;
            font-size: 45px;
            margin-left: auto;
        }
        
        .text-container h3 {
            margin-bottom: 24px;
        }
        
       
        .text-container a {
    
            height: 36px;
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            letter-spacing: 0.14px;
            color: #827C85;
            opacity: 1;
            font-size: 18px;
            font-weight: normal;
            transition: 0.2s;
            text-decoration: none;
            position: absolute;
            top: 20px;
        }
    
        .text-container .download {
            text-decoration: underline;
            border-bottom: 1px solid #000;
            height: 45px;
            width: 97px;
            position: relative;
            &:hover {
                border-bottom-color: #FE3F00;
            }
        }
    
        .text-container a:hover {
            color: #FE3F00;
            top: 15px;
        }
        
        .text-container h3 span {
            color: #3B3655;
        }
        
        .intro {
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            font-weight: 500;
            letter-spacing: 0.14px;
            line-height: 30px;
            color: #3B3655;
            opacity: 1;
            font-size: 18px;
            margin: auto;
            display: block;
        }
        
        .img-container {
            text-align: center;
            margin-left: -40px;
        }
        
        
        .img-container img {
            height: 400px;
        }
    
       
    }
    
    .page5 {
        margin-top: 250px;
    }
    
    .page5title {
        color: #3B3655;
        font-weight: bold;
        font-size: 45px;
        display: block;
        margin: auto;
    }
    
    .sub-head {
        text-align: left;
        font-family: "Apple SD Gothic Neo", Regular;
        font-size: 16px;
        letter-spacing: 0.14px;
        color: #827C85;
        opacity: 1;
        padding-bottom: 8px;
    }

}

@media only screen 
and (min-device-width : 1024px)
and (max-device-width : 1025px)  {

    .page5Container {
        display: flex;
        align-items: center;
        
    
        .page5Container div {
            flex: 1;
        }
        
        .text-container h3, .text-container, .title {
            color: #FE3F00;
            font-weight: bold;
            font-size: 45px;
            margin-left: auto;
        }
        
        .text-container h3 {
            margin-bottom: 24px;
        }
        
       
        .text-container a {
    
            height: 36px;
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            letter-spacing: 0.14px;
            color: #827C85;
            opacity: 1;
            font-size: 18px;
            font-weight: normal;
            transition: 0.2s;
            text-decoration: none;
            position: absolute;
            top: 20px;
        }
    
        .text-container .download {
            text-decoration: underline;
            border-bottom: 1px solid #000;
            height: 45px;
            width: 97px;
            position: relative;
            &:hover {
                border-bottom-color: #FE3F00;
            }
        }
    
        .text-container a:hover {
            color: #FE3F00;
            top: 15px;
        }
        
        .text-container h3 span {
            color: #3B3655;
        }
        
        .intro {
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            font-weight: 500;
            letter-spacing: 0.14px;
            line-height: 30px;
            color: #3B3655;
            opacity: 1;
            font-size: 18px;
            margin: auto;
            display: block;
        }
        
        .img-container {
            text-align: center;
            margin-left: -40px;
        }
        
        
        .img-container img {
            height: 400px;
        }
    
       
    }
    
    .page5 {
        margin-top: 500px;
    }
    
    .page5title {
        color: #3B3655;
        font-weight: bold;
        font-size: 45px;
        display: block;
        margin: auto;
    }
    
    .sub-head {
        text-align: left;
        font-family: "Apple SD Gothic Neo", Regular;
        font-size: 16px;
        letter-spacing: 0.14px;
        color: #827C85;
        opacity: 1;
        padding-bottom: 8px;
    }

}


@media only screen 
and (min-device-width : 100px)
and (max-device-width : 767px)  {

    .page5Container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    
        .page5Container div {
            flex: 1;
        }
        
        .text-container h3, .text-container, .title {
            color: #FE3F00;
            font-weight: bold;
            font-size: 45px;
            margin-left: auto;
        }
        
        .text-container h3 {
            margin-bottom: 24px;
        }
        
       
        .text-container a {
    
            height: 36px;
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            letter-spacing: 0.14px;
            color: #827C85;
            opacity: 1;
            font-size: 18px;
            font-weight: normal;
            transition: 0.2s;
            text-decoration: none;
            position: absolute;
            top: 20px;
        }
    
        .text-container .download {
            text-decoration: underline;
            border-bottom: 1px solid #000;
            height: 45px;
            width: 97px;
            position: relative;
            &:hover {
                border-bottom-color: #FE3F00;
            }
        }
    
        .text-container a:hover {
            color: #FE3F00;
            top: 15px;
        }
        
        .text-container h3 span {
            color: #3B3655;
        }
        
        .intro {
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            font-weight: 500;
            letter-spacing: 0.14px;
            line-height: 30px;
            color: #3B3655;
            opacity: 1;
            font-size: 18px;
            margin: auto;
            display: block;
        }
        
        .img-container {
            text-align: center;
            margin-left: -20px;
            order: 1;
            
        }
        
        .text-container {
            margin-left: 0px;
        }
        
        .img-container img {
            height: 312px;
        }
    
       
    }
    
    .page5 {
        margin-top: 100px;
    }
    
    .page5title {
        color: #3B3655;
        font-weight: bold;
        font-size: 45px;
        display: block;
        margin: auto;
    }
    
    .sub-head {
        text-align: left;
        font-family: "Apple SD Gothic Neo", Regular;
        font-size: 16px;
        letter-spacing: 0.14px;
        color: #827C85;
        opacity: 1;
        padding-bottom: 8px;
    }

}