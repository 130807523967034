.page1 {
    display: flex;

    align-items: center;
    margin-top: 180px;

    .page1 div {
        flex: 1;
    }

    .text-container h3,
    .text-container {
        color: #FE3F00;
        font-weight: bold;
        font-size: 48px;
        font-family: "Noto Sans";
        letter-spacing: 0.19px;

    }

    .text-container h3 {
        margin-bottom: 16px;
    }

    .text-container a {

        height: 36px;
        text-align: left;
        font-family: "Apple SD Gothic Neo", Regular;
        letter-spacing: 0.24px;
        color: #827C85;
        opacity: 1;
        font-size: 22px;
        font-weight: normal;
        transition: 0.2s;
        text-decoration: none;
        position: absolute;
        top: 20px;
    }

    .text-container .download {
        text-decoration: underline;
        border-bottom: 1px solid #000;
        height: 45px;
        width: 180px;
        position: relative;
        &:hover {
            border-bottom-color: #FE3F00;
        }
    }

    .text-container a:hover {
        color: #FE3F00;
        top: 15px;
    }

    .text-container h3 span {
        color: #3B3655;
    }

    .intro {
        text-align: left;
        font-family: "Apple SD Gothic Neo", Regular;
        letter-spacing: 0.24px;
        line-height: 30px;
        color: #827C85;
        opacity: 1;
        font-size: 22px;
        width: 562px;
        margin: auto;
        display: block;
        font-weight: normal;
    }

    .img-container {
        text-align: center;
        margin-left: auto;
    }


    .img-container img {
        height: 500px;
    }

    .side-items {
        position: fixed;
        left: 60px;
        -webkit-text-orientation: mixed;
        text-orientation: mixed;
        height: 400px;
        writing-mode: tb-rl;
        transform: rotate(180deg);
        margin-top: 200px;

        a {
            padding-top: 40px;
            text-align: center;
            font-family: "Apple SD Gothic Neo", Regular;
            letter-spacing: 0.14px;
            color: #827C85;
            opacity: 1;
            cursor: pointer;

        }
    }

    .selected-area {
        font-weight: bold;
        color: #3B3655 !important;
    }


}


@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {
    .page1 {
        display: flex;
    
        align-items: center;
        margin-top: 120px;
    
        .page1 div {
            flex: 1;
        }
    
        .text-container h3,
        .text-container {
            color: #FE3F00;
            font-weight: bold;
            font-size: 32px;
            font-family: "Noto Sans";
            letter-spacing: 0.19px;
            margin-right: 21px;
        }
    
        .text-container h3 {
            margin-bottom: 16px;
        }
    
        .text-container a {
    
            height: 36px;
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            letter-spacing: 0.24px;
            color: #827C85;
            opacity: 1;
            font-size: 18px;
            font-weight: normal;
            transition: 0.2s;
            text-decoration: none;
            position: absolute;
            top: 20px;
        }
    
        .text-container .download {
            text-decoration: underline;
            border-bottom: 1px solid #000;
            height: 45px;
            width: 145px;
            position: relative;
            &:hover {
                border-bottom-color: #FE3F00;
            }
        }
    
        .text-container a:hover {
            color: #FE3F00;
            top: 15px;
        }
    
        .text-container h3 span {
            color: #3B3655;
        }
    
        .intro {
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            letter-spacing: 0.24px;
            line-height: 30px;
            color: #827C85;
            opacity: 1;
            font-size: 18px;
            width: 337px;
            margin: auto;
            display: block;
            font-weight: normal;
        }
    
        .img-container {
            text-align: center;
            margin-left: auto;
        }
    
    
        .img-container img {
            height: 300px;
        }
    
        .side-items {
            position: fixed;
            left: 30px;
            -webkit-text-orientation: mixed;
            text-orientation: mixed;
            height: 400px;
            writing-mode: tb-rl;
            transform: rotate(180deg);
            margin-top: 200px;
    
            a {
                padding-top: 40px;
                text-align: center;
                font-family: "Apple SD Gothic Neo", Regular;
                letter-spacing: 0.14px;
                color: #827C85;
                opacity: 1;
                cursor: pointer;
    
            }
        }
    
        .selected-area {
            font-weight: bold;
            color: #3B3655 !important;
        }
    
    
    }
    


}


@media only screen 
and (min-device-width : 100px) 
and (max-device-width : 767px)  {
    .page1 {
        display: flex;
        flex-direction: column;
    
        align-items: center;
        margin-top: 120px;
    
        .page1 div {
            flex: 1;
        }
    
        br{
            display:none;
           }
       
        .text-container h3,
        .text-container {
            color: #FE3F00;
            font-weight: bold;
            font-size: 24px;
            font-family: "Noto Sans";
            letter-spacing: 0.19px;
            margin-right: 21px;
        }
    
        .text-container h3 {
            margin-bottom: 16px;
        }
    
        .text-container a {
    
            height: 36px;
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            letter-spacing: 0.24px;
            color: #827C85;
            opacity: 1;
            font-size: 18px;
            font-weight: normal;
            transition: 0.2s;
            text-decoration: none;
            position: absolute;
            top: 20px;
        }
    
        .text-container .download {
            text-decoration: underline;
            border-bottom: 1px solid #000;
            height: 45px;
            width: 145px;
            position: relative;
            &:hover {
                border-bottom-color: #FE3F00;
            }
        }
    
        .text-container a:hover {
            color: #FE3F00;
            top: 15px;
        }
    
        .text-container h3 span {
            color: #3B3655;
        }
    
        .intro {
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            letter-spacing: 0.24px;
            line-height: 30px;
            color: #827C85;
            opacity: 1;
            font-size: 18px;
            width: 100%;
            margin: auto;
            display: block;
            font-weight: normal;
        }
    
        .img-container {
            text-align: center;
            margin-left: auto;
            margin-top: 50px;
        }
    
    
        .img-container img {
            height: 300px;
        }
    
        .side-items {
            position: fixed;
            left: 30px;
            -webkit-text-orientation: mixed;
            text-orientation: mixed;
            height: 400px;
            writing-mode: tb-rl;
            transform: rotate(180deg);
            margin-top: 200px;
    
            a {
                padding-top: 40px;
                text-align: center;
                font-family: "Apple SD Gothic Neo", Regular;
                letter-spacing: 0.14px;
                color: #827C85;
                opacity: 1;
                cursor: pointer;
    
            }
        }
    
        .selected-area {
            font-weight: bold;
            color: #3B3655 !important;
        }
        .hide {
            display: none;
        }
    
    
    }
    
}