.App {
    padding: 0px 250px;
    font-family: "Apple SD Gothic Neo", Regular !important;
    background-image: url(./assets/line@2x.jpg);
    background-repeat: no-repeat;
    background-position: 252px 1430px;
}


.movetotop {
    right: 50px;
    position: fixed;
    bottom: 0px;
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  { 

    .App {
        padding: 0px 80px;
        font-family: "Apple SD Gothic Neo", Regular !important;
        background-image: url(/static/media/line@2x.1d5b357b.jpg);
        background-repeat: no-repeat;
        background-position: 85px 855px;
        background-size: 570px;
    }
    
    
    .movetotop {
        right: 50px;
        position: fixed;
        bottom: 0px;
        img {
            height: 40px;
        }
    }
}

@media only screen 
and (min-device-width : 1024px)
and (max-device-width : 1025px)
  { 

    .App {
        background-repeat: no-repeat;
        background-position: 80px 1090px;
        background-size: 900px;  
    }
}


@media only screen 
and (min-device-width : 100px) 
and (max-device-width : 767px)  { 

    .App {
        padding: 0px 61px 0px 37px;
        font-family: "Apple SD Gothic Neo", Regular !important;
        background-image: none;

    }
    
    
    .movetotop {
        right: 70px;
        position: fixed;
        bottom: 0px;
        img {
            height: 40px;
        }
    }

    .side-items {
        display: none;
    }
}