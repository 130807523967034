.page6{
    margin-top: 500px;

    .title {
        color: #3B3655;
        font-size: 48px;
        font-weight: bold;
        font-family: "Noto Sans";
    }

    .text-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #827C85;
    border-radius: 17px;
    opacity: 1;
    padding: 50px;
    }

    .sub-head {
    text-align: left;
    font-family: "Noto Sans";
    font-weight: bold;
    letter-spacing: 0.24px;
    color: #3B3655;
    font-size: 22px;
    }

    .text-content {
    margin-top: 10px;
    text-align: left;
    letter-spacing: 0.19px;
    color: #827C85;
    opacity: 1;
    font-family: "Apple SD Gothic Neo", Regular;
    line-height: 30px;
    font-size: 18px;
    }

    button {
        width: 160px;
        height: 46px;
        background: #FE3F00 0% 0% no-repeat padding-box;
        opacity: 1;
        letter-spacing: 0.19px;
        color: #FDFDFD;
        border-radius: 30px;
        font-size: 18px;
        font-weight: bold;
        font-family: "Noto Sans";
        margin-top: 32px;
        outline: none;
    }
}



@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {

    .page6{
        margin-top: 250px;
    
        .title {
            color: #3B3655;
            font-size: 32px;
            font-weight: bold;
            font-family: "Noto Sans";
        }
    
        .text-container {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #827C85;
        border-radius: 17px;
        opacity: 1;
        padding: 50px;
        }
    
        .sub-head {
        text-align: left;
        font-family: "Noto Sans";
        font-weight: bold;
        letter-spacing: 0.24px;
        color: #3B3655;
        font-size: 24px;
        }
    
        .text-content {
        margin-top: 10px;
        text-align: left;
        letter-spacing: 0.19px;
        color: #827C85;
        opacity: 1;
        font-family: "Apple SD Gothic Neo", Regular;
        line-height: 30px;
        font-size: 18px;
        }
    
        button {
            width: 129px;
            height: 44px;
            background: #FE3F00 0% 0% no-repeat padding-box;
            opacity: 1;
            letter-spacing: 0.19px;
            color: #FDFDFD;
            border-radius: 30px;
            font-size: 18px;
            font-weight: bold;
            font-family: "Noto Sans";
            margin-top: 32px;
            outline: none;
        }
    }
    

}

@media only screen 
and (min-device-width : 1024px)
and (max-device-width : 1025px)  {

    .page6{
        margin-top: 500px;
    
        .title {
            color: #3B3655;
            font-size: 32px;
            font-weight: bold;
            font-family: "Noto Sans";
        }
    
        .text-container {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #827C85;
        border-radius: 17px;
        opacity: 1;
        padding: 50px;
        }
    
        .sub-head {
        text-align: left;
        font-family: "Noto Sans";
        font-weight: bold;
        letter-spacing: 0.24px;
        color: #3B3655;
        font-size: 24px;
        }
    
        .text-content {
        margin-top: 10px;
        text-align: left;
        letter-spacing: 0.19px;
        color: #827C85;
        opacity: 1;
        font-family: "Apple SD Gothic Neo", Regular;
        line-height: 30px;
        font-size: 18px;
        }
    
        button {
            width: 129px;
            height: 44px;
            background: #FE3F00 0% 0% no-repeat padding-box;
            opacity: 1;
            letter-spacing: 0.19px;
            color: #FDFDFD;
            border-radius: 30px;
            font-size: 18px;
            font-weight: bold;
            font-family: "Noto Sans";
            margin-top: 32px;
            outline: none;
        }
    }
    

}

@media only screen 
and (min-device-width : 100px)
and (max-device-width : 767px)  {

    .page6{
        margin-top: 100px;
    
        .title {
            color: #3B3655;
            font-size: 32px;
            font-weight: bold;
            font-family: "Noto Sans";
        }
    
        .text-container {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #827C85;
        border-radius: 17px;
        opacity: 1;
        padding: 20px;
        }
    
        .sub-head {
        text-align: left;
        font-family: "Noto Sans";
        font-weight: bold;
        letter-spacing: 0.24px;
        color: #3B3655;
        font-size: 24px;
        }
    
        .text-content {
        margin-top: 10px;
        text-align: left;
        letter-spacing: 0.19px;
        color: #827C85;
        opacity: 1;
        font-family: "Apple SD Gothic Neo", Regular;
        line-height: 30px;
        font-size: 18px;
        }
    
        button {
            width: 129px;
            height: 44px;
            background: #FE3F00 0% 0% no-repeat padding-box;
            opacity: 1;
            letter-spacing: 0.19px;
            color: #FDFDFD;
            border-radius: 30px;
            font-size: 18px;
            font-weight: bold;
            font-family: "Noto Sans";
            margin-top: 32px;
            outline: none;
        }
    }
    

}