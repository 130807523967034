.page2 {
    margin-top: 500px;

    h3 {
        color: #3B3655;
        font-weight: bold;
        font-size: 48px;
        font-family: "Noto Sans";
    }

    .page2Container {
        display: flex;

        align-items: center;


        .page2Container div {
            flex: 1;
        }

        .text-container h3,
        .text-container,
        .title {
            color: #FE3F00;
            font-weight: bold;
            font-size: 48px;
            font-family: "Noto Sans";
        }

        .text-container h3 {
            margin-bottom: 24px;
        }

        
    .text-container a {

        height: 36px;
        text-align: left;
        font-family: "Apple SD Gothic Neo", Regular;
        letter-spacing: 0.24px;
        color: #827C85;
        opacity: 1;
        font-size: 22px;
        font-weight: normal;
        transition: 0.2s;
        text-decoration: none;
        position: absolute;
        top: 20px;
    }

    .text-container .download {
        text-decoration: underline;
        border-bottom: 1px solid #000;
        height: 45px;
        width: 120px;
        position: relative;
        &:hover {
            border-bottom-color: #FE3F00;
        }
    }

    .text-container a:hover {
        color: #FE3F00;
        top: 15px;
    }
        .text-container h3 span {
            color: #3B3655;
        }

        .intro {
            text-align: left;
            font-family: "Apple SD Gothic Neo";
            letter-spacing: 0.24px;
            line-height: 30px;
            color: #3B3655;
            opacity: 1;
            font-size: 22px;
            font-weight: 500;
            width: 328px;
            margin: auto;
            display: block;
        }

        .img-container {
            text-align: center;
            margin-left: auto;
            margin-right: -30px;
        }

        .img-container img {
            height: 600px;
        }


    }

    .page2title {
        color: #3B3655;
        font-weight: bold;
        font-size: 45px;
        display: block;
        margin: auto;
    }

    .sub-head {
        text-align: left;
        font-family: "Apple SD Gothic Neo", Regular;
        font-size: 18px;
        letter-spacing: 0.19px;
        color: #827C85;
        opacity: 1;
        padding-bottom: 8px;
    }
}



@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {

    .page2 {
        margin-top: 250px;
    
        h3 {
            color: #3B3655;
            font-weight: bold;
            font-size: 48px;
            font-family: "Noto Sans";
        }
    
        .page2Container {
            display: flex;
    
            align-items: center;
    
    
            .page2Container div {
                flex: 1;
            }
    
            .text-container h3,
            .text-container,
            .title {
                color: #FE3F00;
                font-weight: bold;
                font-size: 32px;
                font-family: "Noto Sans";
            }
    
            .text-container h3 {
                margin-bottom: 24px;
            }
    
            
        .text-container a {
    
            height: 36px;
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            letter-spacing: 0.14px;
            color: #827C85;
            opacity: 1;
            font-size: 18px;
            font-weight: normal;
            transition: 0.2s;
            text-decoration: none;
            position: absolute;
            top: 20px;
        }
    
        .text-container {

            .download {
                text-decoration: underline;
                border-bottom: 1px solid #000;
                height: 45px;
                width: 97px;
                position: relative;
                &:hover {
                    border-bottom-color: #FE3F00;
                }
            }

            img {
                height: 46px;
            }
        }
    
        .text-container a:hover {
            color: #FE3F00;
            top: 15px;
        }
            .text-container h3 span {
                color: #3B3655;
            }
    
            .intro {
                text-align: left;
                font-family: "Apple SD Gothic Neo";
                letter-spacing: 0.14px;
                line-height: 30px;
                color: #3B3655;
                opacity: 1;
                font-size: 18px;
                font-weight: 500;
                width: 328px;
                margin: auto;
                display: block;
            }
    
            .img-container {
                text-align: center;
                margin-left: auto;
                margin-right: -30px;
            }
    
            .img-container img {
                height: 312px;
            }
    
    
        }
    
        .page2title {
            color: #3B3655;
            font-weight: bold;
            font-size: 45px;
            display: block;
            margin: auto;
        }
    
        .sub-head {
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            font-size: 16px;
            letter-spacing: 0.19px;
            color: #827C85;
            opacity: 1;
            padding-bottom: 8px;
        }
    }
    

}

@media only screen  
and (min-device-width : 1024px)
and (max-device-width : 1025px)  {

    .page2 {
        margin-top: 500px;
    
        h3 {
            color: #3B3655;
            font-weight: bold;
            font-size: 48px;
            font-family: "Noto Sans";
        }
    
        .page2Container {
            display: flex;
    
            align-items: center;
    
    
            .page2Container div {
                flex: 1;
            }
    
            .text-container h3,
            .text-container,
            .title {
                color: #FE3F00;
                font-weight: bold;
                font-size: 32px;
                font-family: "Noto Sans";
            }
    
            .text-container h3 {
                margin-bottom: 24px;
            }
    
            
        .text-container a {
    
            height: 36px;
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            letter-spacing: 0.14px;
            color: #827C85;
            opacity: 1;
            font-size: 18px;
            font-weight: normal;
            transition: 0.2s;
            text-decoration: none;
            position: absolute;
            top: 20px;
        }
    
        .text-container {

            .download {
                text-decoration: underline;
                border-bottom: 1px solid #000;
                height: 45px;
                width: 97px;
                position: relative;
                &:hover {
                    border-bottom-color: #FE3F00;
                }
            }

            img {
                height: 46px;
            }
        }
    
        .text-container a:hover {
            color: #FE3F00;
            top: 15px;
        }
            .text-container h3 span {
                color: #3B3655;
            }
    
            .intro {
                text-align: left;
                font-family: "Apple SD Gothic Neo";
                letter-spacing: 0.14px;
                line-height: 30px;
                color: #3B3655;
                opacity: 1;
                font-size: 18px;
                font-weight: 500;
                width: 328px;
                margin: auto;
                display: block;
            }
    
            .img-container {
                text-align: center;
                margin-left: auto;
                margin-right: -30px;
            }
    
            .img-container img {
                height: 312px;
            }
    
    
        }
    
        .page2title {
            color: #3B3655;
            font-weight: bold;
            font-size: 45px;
            display: block;
            margin: auto;
        }
    
        .sub-head {
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            font-size: 16px;
            letter-spacing: 0.19px;
            color: #827C85;
            opacity: 1;
            padding-bottom: 8px;
        }
    }
    

}

@media only screen  
and (min-device-width : 100px)
and (max-device-width : 767px)  {

    .page2 {
        margin-top: 100px;
    
        h3 {
            color: #3B3655;
            font-weight: bold;
            font-size: 48px;
            font-family: "Noto Sans";
        }
    
        .page2Container {
            flex-direction: column;
    
            align-items: flex-start;
    
    
            .page2Container div {
                flex: 1;
            }
    
            .text-container h3,
            .text-container,
            .title {
                color: #FE3F00;
                font-weight: bold;
                font-size: 32px;
                font-family: "Noto Sans";
            }
    
            .text-container h3 {
                margin-bottom: 24px;
            }
    
            
        .text-container a {
    
            height: 36px;
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            letter-spacing: 0.14px;
            color: #827C85;
            opacity: 1;
            font-size: 18px;
            font-weight: normal;
            transition: 0.2s;
            text-decoration: none;
            position: absolute;
            top: 20px;
        }
    
        .text-container {

            .download {
                text-decoration: underline;
                border-bottom: 1px solid #000;
                height: 45px;
                width: 97px;
                position: relative;
                &:hover {
                    border-bottom-color: #FE3F00;
                }
            }

            img {
                height: 46px;
                display: none;
            }
        }
    
        .text-container a:hover {
            color: #FE3F00;
            top: 15px;
        }
            .text-container h3 span {
                color: #3B3655;
            }
    
            .intro {
                text-align: left;
                font-family: "Apple SD Gothic Neo";
                letter-spacing: 0.14px;
                line-height: 30px;
                color: #3B3655;
                opacity: 1;
                font-size: 18px;
                font-weight: 500;
                width: 100%;
                margin: auto;
                display: block;
            }
    
            .img-container {
                text-align: center;
                margin-left: -25px;
                margin-right: 0px;
            }
    
            .img-container img {
                height: 312px;
            }
    
    
        }
    
        .page2title {
            color: #3B3655;
            font-weight: bold;
            font-size: 45px;
            display: block;
            margin: auto;
        }
    
        .sub-head {
            text-align: left;
            font-family: "Apple SD Gothic Neo", Regular;
            font-size: 16px;
            letter-spacing: 0.19px;
            color: #827C85;
            opacity: 1;
            padding-bottom: 8px;
        }
    }
    

}