.page7 {
    margin-top: 250px;
    margin-bottom: 100px;

    .title {
        font-family: "Noto Sans";
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.24px;
        color: #3B3655;
        margin-bottom: 20px;
    }

    .sub-text {
        text-align: center;
        font-family: "Apple SD Gothic Neo", Regular;
        font-size: 18px;
        letter-spacing: 0.19px;
        color: #827C85;
        opacity: 1;
        line-height: 30px;
    }

    .contact-img {
        text-align: center;
        margin-top: 60px;
       img {
        margin: 0px 30px;
        height: 32px;
       }
    }

}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  {


    .page7 {
        margin-top: 250px;
        margin-bottom: 100px;
    
        .title {
            font-family: "Noto Sans";
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            letter-spacing: 0.19px;
            color: #3B3655;
            margin-bottom: 20px;
        }
    
        .sub-text {
            text-align: center;
            font-family: "Apple SD Gothic Neo", Regular;
            font-size: 18px;
            letter-spacing: 0.19px;
            color: #827C85;
            opacity: 1;
            line-height: 30px;
        }
    
        .contact-img {
            text-align: center;
            margin-top: 60px;
           img {
            margin: 0px 30px;
            height: 30px;
           }
        }
    
       
    
    }

    
}


@media only screen 
and (min-device-width : 100px) 
and (max-device-width : 767px)  {


    .page7 {
        margin-top: 100px;
        margin-bottom: 100px;
    
        .title {
            font-family: "Noto Sans";
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            letter-spacing: 0.19px;
            color: #3B3655;
            margin-bottom: 20px;
        }
    
        .sub-text {
            text-align: center;
            font-family: "Apple SD Gothic Neo", Regular;
            font-size: 18px;
            letter-spacing: 0.19px;
            color: #827C85;
            opacity: 1;
            line-height: 30px;
        }
    
        .contact-img {
            text-align: center;
            margin-top: 60px;
           img {
            margin: 0px 24px;
            height: 24px;
           }
        }
    
       
    
    }

    
}

